.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;
}
.modal-dialog {
  max-width: 90%;
  display: flex;
  justify-content: center;
  .modal-content {
    padding: 24px;
    box-shadow: 0px 0px 11px 2px rgb(0 0 0 / 19%);
    width: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 12px;
    outline: 0;
    border: none !important;
    opacity: 1;
    z-index: 99999;
    // &.new-modal-content {
    //   padding: 16px 12px !important;
    // }
    .modal-header {
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      color: var(--eco-base-color);
      margin: 0px 0px 24px 0px;
      padding: 0;
      border-bottom: 0 !important;
      display: block;
      .model-close-btn {
        position: absolute;
        right: 14px;
        top: 18px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        cursor: pointer;
        background-color: #f2f2f2;
        border-radius: 50%;
        img {
          width: 25px;
        }
        // &:hover {
        //   cursor: pointer;
        //   background-color: #f2f2f2;
        //   border-radius: 50%;
        //   // background: #e6f0ff;
        // }
      }
    }
    .modal-body {
      overflow-y: auto;
      max-height: calc(100vh - 253px);
      // padding: 0 8px;
      // overflow-x: hidden;
      padding: 0px;
      min-width: 568px !important;
      .w-3 {
        width: 300px !important;
      }

      .w-7 {
        width: 700px !important;
      }

      .w-6 {
        width: 600px !important;
      }
      .w-8 {
        width: 800px !important;
      }
      .w-5 {
        width: 500px !important;
      }
      .w-4 {
        width: 400px !important;
      }
      .w-9 {
        width: 900px !important;
      }

      .w-10 {
        width: 1000px !important;
        @media screen and (max-width: 1192px) {
          width: calc(100vw - 145px) !important;
        }
      }
      .w-11 {
        width: 1100px !important;
        // width: calc(100vw - 354px);
        @media screen and (max-width: 1192px) {
          width: calc(100vw - 145px) !important;
        }
      }
      // @media screen and (max-width: 768px) {
      //   .w-11 {
      //     width: 600px;
      //   }
      //   .w-8 {
      //     width: 800px;
      //   }
      // }

      .w-12 {
        width: 1200px;
      }
      .w-13 {
        width: calc(100vw - 191px);
      }
    }
    .modal-footer {
      border-top: 0px;
      flex-wrap: nowrap;
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: 8px;
      padding: 8px 0 0 !important;
      // margin-top: 32px;
      button {
        margin: 0;
        width: max-content;
      }
    }
  }
}

modal-container {
  background-color: #4242423b !important;
}

.modal-dialog {
  // background-color: white;
}

.modal-backdrop {
  background-color: white;
  &.show {
    opacity: 0;
  }
}
