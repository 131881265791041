// Variable overrides
// Variable overrides
// Global variables
$tb-border-radius: 8px !default;
$tb-box-shadow: 0 2px 6px #1a181e0a !default;
$tb-border-radius-min: 4px !default;
$border-color: #d9d9d9;
:root {
  --eco-base-color: #1a181e;
  --eco-base-secondary-color: #666666;
  // --eco-primary-color: #00509d;
  --eco-primary-color: #4f46e5;
  --placeholder-color: #989898;
  --eco-btn-font-size: 16px;
  --eco-text-warning: #f4b815;
  --eco-dropdown-hover-bg: rgba(0, 0, 0, 0.04) !important;
  --eco-warning-color: #ee741f;
  // font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
  //   "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  --eco-green-color: #0f9d58;
  --eco-green-pale-color: #f0fff0;
  --eco-red-color: #e50b20;
  --eco-yellow-color: #f4b815;
  --eco-primary-pale-color: #f3f8ff;
  --eco-font-size: 16px;
  --eco-border-color: rgba(0, 0, 0, 0.1);
  --eco-box-shadow: 0 2px 6px #1a181e0a;
  --eco-material-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  // Font-weights
  --eco--border-radius-min: 4px;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --highlight-text-color: #136be6;
  --primary-color: #136be6;
  // Secondary font colors

  --secondary-text-color: #e0e0e0;

  // Border colors

  --secondary-border-color: #eceff1;
}
