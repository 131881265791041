.pb-16px {
  padding-bottom: 16px;
}

.pb-8px {
  padding-bottom: 8px;
}

.p-8px {
  padding: 8px;
}

.p-16px {
  padding: 16px;
}

.p-24px {
  padding: 24px;
}

.p-32px {
  padding: 32px;
}

.pt-16px {
  padding-top: 16px;
}

.pt-24px {
  padding-top: 24px;
}

.pt-8px {
  padding-top: 8px;
}

.pr-8px {
  padding-right: 8px;
}

.pr-16px {
  padding-right: 16px;
}

.pr-24px {
  padding-right: 24px;
}

.pl-8px {
  padding-left: 8px;
}

.pl-16px {
  padding-left: 16px;
}

.pl-24px {
  padding-left: 24px;
}
.pl-32px {
  padding-left: 32px;
}
.pb-32px {
  padding-bottom: 32px;
}

.pb-24px {
  padding-bottom: 24px !important;
}
