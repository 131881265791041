// Here you can add other styles
body {
  // font-family: Inter, "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', Segoe UI Symbol,
    'Noto Color Emoji' !important;
  background: white !important;
  color: #1c293a !important;
  @media only screen and (max-width: 1024px) {
    // font-size: 13px !important;
  }
}
.app,
app-login,
app-root {
  //   display: flex;
  //   flex-direction: column;
  min-height: 100vh;
}
*:focus {
  outline: none !important;
}
input:focus {
  // border: 1px solid #20a8d8;
}

.transition-300 {
  transition: all ease-in-out 300ms;
}

.is-invalid {
  position: relative;
  animation: shake 0.1s linear;
  animation-iteration-count: 3;
}

.is-invalid-ns {
  border-color: red !important;
  position: relative !important;
}

@keyframes shake {
  0% {
    left: -5px;
  }

  100% {
    right: -5px;
  }
}

.dropdown {
  &:hover {
    .dropdown-menu {
      display: block !important;
    }
  }
}
.pointer {
  cursor: pointer !important;
}
.default {
  cursor: default;
}
.overflow-hidden {
  //   white-space: nowrap;
  overflow: hidden;
  //   text-overflow: ellipsis;
}
a {
  color: #333;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  //   z-index: 99999 !important;
}

.owl-dt-popup-container {
  font-size: 12px !important;
}

// .form-control[readonly] {
//   background-color: white;
// }

.hint {
  font-size: 13px;
  line-height: 20px;
  color: #6c757d;
}

.main .container-fluid {
  padding: 0 !important;
}

.-m-10 {
  margin: -10px;
}

// .form-control:focus {
//   box-shadow: none !important;
// }

.hrefLink:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
  color: #284255 !important;
}

.table-Header {
  font-size: 12px;
  font-weight: 600;
  margin: 24px 0 16px;
  color: #535766;
}

.green {
  color: #0d6907;
}

.red {
  color: #d31111;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #ddd;
}
.center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ng-autocomplete {
  width: 100% !important;
}

.p-r-0 {
  padding-right: 0px;
}

.p-l-0 {
  padding-left: 0px;
}

.grid-col-container {
  // border-bottom: 1px solid #dfe3e6;
  margin: 0 !important;
  .grid-col {
    // margin-left: -1px;
    margin-top: -1px;
    border: 1px solid #dfe3e6;
    padding: 0.75rem;
    // &:nth-child(odd) {
    //   border-left: 1px solid #dfe3e6;
    //   border-top: 1px solid #dfe3e6;
    // }
    // &:nth-child(even) {
    //   border-left: 1px solid #dfe3e6;
    //   border-top: 1px solid #dfe3e6;
    //   border-right: 1px solid #dfe3e6;
    // }
  }
}

// .action-icon-parent {
//   .action-icon {
//     cursor: pointer;
//     color: #8c9398;
//     opacity: 0.4;
//     transition: all ease-in 0.3s;
//   }
//   &:hover {
//     .action-icon {
//       opacity: 1;
//     }
//     .action-icon-danger {
//       color: red;
//     }
//   }
// }

// .custom-fade {
//   -webkit-mask-image: -webkit-gradient(
//     linear,
//     left top,
//     left bottom,
//     from(rgba(0, 0, 0, 1)),
//     to(rgba(0, 0, 0, 0))
//   );
// }

.custom-asidebar-hide {
  .custom-asidebar {
    right: -433px !important;
  }
}
.w-10p {
  width: 10%;
}
.w-20p {
  width: 20%;
}
.w-30p {
  width: 30%;
}
.w-40p {
  width: 40%;
}
.w-50p {
  width: 50%;
}
.w-60p {
  width: 60%;
}
.w-70p {
  width: 70%;
}
.w-80p {
  width: 80%;
}
.w-90p {
  width: 90%;
}

.w-5p {
  width: 5%;
}
.w-100px {
  width: 100px;
}

.text-right {
  text-align: right !important;
}

.custom-header {
  display: grid;
  grid-template-columns: 1fr;
  font-weight: 400;
  // border-top-left-radius: 0.3rem;
  // border-top-right-radius: 0.3rem;
  // color: #ffffff;
  color: white;
  padding: 0.4rem 0.25rem;
  font-size: 1rem;
  // border-radius: 0px;
  // background-color: #2673af;
}

.btn-foursquare {
  font-weight: 600;
}

.flex {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
}
.create {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.max-one-line-text {
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.max-two-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.required:after {
  content: ' *';
  color: red;
}

button:disabled,
button[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.datepicker-today {
  border: 1px solid;
}

.list {
  margin: 8px;
}

.filter {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.input-group {
  height: 40px;
}
.inner-addon {
  position: relative;
  width: 426px;
}

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  padding: 13px;
  color: #787b7d;
  pointer-events: none;
}

/* align icon */
.left-addon .glyphicon {
  left: 0px;
}
.right-addon .glyphicon {
  right: 0px;
}

/* add padding  */
.left-addon input {
  height: 34px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: $tb-box-shadow;
}
.right-addon input {
  padding-right: 30px;
}
.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px;
}

.vertically-center {
  margin: auto 0;
}

.notice {
  background-color: #fded9d66;
  border: 2px solid #ffee994f;
  color: #525252;
  padding: 0.5rem;
  font-size: 0.75rem;
  border-radius: 5px;
}

.empty {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.border-r-50-p {
  border-radius: 50% !important;
}

.flex {
  margin-bottom: 0px;
}

.clickable {
  text-decoration: underline;
  color: #076b88b5;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    color: #076b88;
  }
}

.fs-10 {
  font-size: 10px;
}

// ::placeholder {
//   color: #80808061 !important;
// }

.b-12-shadow {
  box-shadow: 0px 3px 8px 2px rgb(33 32 32 / 20%);
  border-radius: 12px;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.ngx-pagination {
  margin-bottom: 0px !important;
}

.width_max_content {
  width: max-content;
}

.text-underline {
  text-decoration: underline;
}

// label {
//   color: #73818f !important;
// }

.ngx-pagination {
  padding-left: 0 !important;
}

p {
  //   margin: 0 0 1px;
}
h1 {
  font-size: 24px;
  margin-bottom: 16px;
  //   text-align: center;
}

::-webkit-input-placeholder {
  /* Edge */
  // color: red;
}
input {
  outline: none !important;
}

.inline-grid {
  display: inline-grid;
}

.commission-pop-over {
  box-shadow: 0px 0px 8px -1px rgb(0 0 0 / 75%);
  width: 250px;
  border-radius: 12px;
  padding: 16px;
}

.bs-datepicker-head {
  background-color: var(--tee-green-color);
  border-radius: 0px;
  margin-right: 4px;
}
.bs-datepicker .bs-media-container {
  gap: 6px;
}
.bs-datepicker-body table th {
  color: rgb(119, 119, 119);
  font-size: 13px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.bs-datepicker-body table.days span {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0px;
}
.bs-datepicker-body table td span.selected {
  background-color: #5cb85c;
  border-radius: 0;
}
.bs-datepicker-body table td span {
  margin: 0;
  font-size: 13px;
  border-radius: 0;
}

::ng-deep {
  /* to penetrate <ngx-editor> CSS styling */

  .NgxEditor {
    overflow-y: scroll;
    p {
      color: var(--eco-base-color);
      font-size: 13px;
    }
  }

  .NgxEditor__Content {
    padding: 12px 16px !important;
  }
}

.amenities {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  .amenity {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    .fa {
      font-size: 20px !important;
    }
  }
}

.rotate-180 {
  transition: 500ms ease all;
  transform: rotate(180deg);
}

.h-500px {
  height: 500px;
}

.form-group {
  .input-with-loader {
    position: relative;
    .input-loader {
      position: absolute;
      top: 15px;
      right: 16px;
    }
  }
}

.p-tiered-menu-header-left {
  left: 84% !important;
  top: 76px !important;
  position: fixed !important;
  display: block !important;
  .log-out {
    .pi-menu-icon {
      color: var(--tee-red-color) !important;
    }
    .p-menuitem-text {
      color: var(--tee-red-color) !important;
      .pi {
      }
    }
  }
}

.p-tiered-menu-google-connect {
  left: 145px !important;
  top: 49px !important;
  display: block !important;
}

.text-primary-hover {
  &:hover {
    color: var(--tee-primary-color);
    cursor: pointer;
  }
}

.trim-long-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.angular-editor {
  .angular-editor-toolbar {
    // font: inherit !important;
    // font-size: 12px !important;
    // padding: 8px !important;
    border-radius: 4px;
    .angular-editor-toolbar-set {
      .ae-font .ae-picker-label {
        // height: 41px !important;
        // font-size: 14px !important;
      }
      .angular-editor-button {
        // font-size: 16px;
        // padding: 8px 12px !important;
        // margin-right: 6px;
      }
    }
  }

  .angular-editor-textarea {
    border-radius: 4px;
    // padding: 32px 32px !important;
    h4 {
      margin-bottom: 16px;
    }
    ul {
      list-style: auto;
      margin-left: 32px;
      li {
        margin-bottom: 12px;
        margin-left: 16px;
        font-size: 16px;
      }
    }
  }
}

.p-inputtext-sm {
  .p-inputtext {
    font-size: 13px !important;
    padding: 8px 12px !important;
  }
}

.opacity-0 {
  opacity: 0;
}

.time-line {
  // border-left: 1px solid #dee2e6 !important;
  flex-direction: row;
  width: 463px;
  display: flex;
  & > div:last-child {
    &.time-line-event {
      .time-line-seperator {
        .time-line-connector {
          display: none;
        }
      }
    }
  }
  .time-line-event {
    flex-direction: column;
    flex: 1;
    display: flex;
    position: relative;
    min-height: 70px;
    // margin-bottom: 40px;

    .time-line-opposite {
      // padding: 1rem 0;
      .time-line-marker-name {
        font-weight: 500;
      }
    }
    .time-line-seperator {
      flex-direction: row;
      flex: 0;
      display: flex;
      align-items: center;
      margin: 16px 0 8px 0;
      .time-line-marker {
        border: 2px solid var(--tee-primary-color);
        border-radius: 50%;
        width: 12px;
        height: 12px;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        // background-color: #dee2e6;
        .is-icon {
          display: none;
        }
      }
      .time-line-connector {
        border: 1px dashed #dee2e6;
        // height: 2px;
        width: 100%;
        // background-color: #dee2e6;
      }
    }
    .time-line-content {
      // padding: 8px 0;
      .date {
        font-size: 11px;
        font-weight: 500;
        color: #707070;
        // color: #909090;
      }
      .name {
        font-weight: 600;
        font-size: 12px;
      }
    }
    &.time-line-completed {
      .time-line-seperator {
        .time-line-marker {
          background-color: var(--tee-primary-color) !important;
          .is-icon {
            display: block;
          }
        }
        .time-line-connector {
          border: 0px !important;
          height: 2px;
          background: var(--tee-primary-color) !important;
        }
      }
    }
    &.time-line-partial {
      .time-line-seperator {
        .time-line-marker {
          background-color: var(--tee-primary-color) !important;
          .is-icon {
            display: block;
          }
        }
        .time-line-connector {
          border: 0px !important;
          height: 2px;
          background: linear-gradient(
            90deg,
            var(--tee-primary-color) 50%,
            #dee2e6 50%
          ) !important;
        }
      }
    }
  }
}

.usassigned-tiered-menu {
  p-tieredmenusub {
    ul {
      li {
        a {
          &.p-menuitem-link {
            padding: 13px;
            font-size: 13px;
          }
        }
        .p-submenu-list {
          max-height: 150px;
          overflow: auto;
          border-radius: 6px;
        }
      }
    }
  }
}

.text-orange {
  color: var(--tee-warning-color);
  font-weight: 500;
}

.customized-timeline {
  .p-timeline-event-marker {
    border-radius: 50%;
    padding: 12px 14px;
  }
}

.tab-view-padding-0px {
  .p-tabview-panels {
    padding: 0 !important;
  }
}

.compact-paginator {
  .mat-paginator-container {
    padding: 0px;

    .mat-paginator-page-size,
    .mat-paginator-range-actions {
      margin: 0px;
    }
  }
}

.eco-clickable {
  color: var(--eco-primary-color);
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--eco-primary-color);
    // font-weight: 600;
  }
}

.value-options-grid-wrapper {
  $grid: repeat(5, 2fr) 1fr 36px;
  grid-template-columns: $grid;
  gap: 10px;

  &.cdk-drag-preview {
    grid-template-columns: $grid;
    background-color: white;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}

a.jsoneditor-poweredBy {
  display: none;
}

.grid.items-center.gap-4.px-6.md\:px-8.border-b:hover {
  background: #f2f4f6;
}

.value-options-grid.cdk-drop-list-dragging
  .value-options-grid-wrapper:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.value-options-grid-wrapper,
.tab-grid-wrapper {
  &.cdk-drag-placeholder {
    opacity: 0;
  }

  &.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.value-options-grid-wrapper {
  $grid: repeat(5, 2fr) 1fr 36px;
  grid-template-columns: $grid;
  gap: 10px;

  &.cdk-drag-preview {
    grid-template-columns: $grid;
    background-color: white;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}

.value-options-grid.cdk-drop-list-dragging
  .value-options-grid-wrapper:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.value-options-grid-wrapper,
.tab-grid-wrapper {
  &.cdk-drag-placeholder {
    opacity: 0;
  }

  &.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.product-button {
  padding: 0px 10px !important;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.mat-tooltip {
  font-size: 13px;
}

.search-attribute-input {
  .mat-form-field-wrapper {
    height: 100%;
    margin-bottom: 0px !important;

    .mat-form-field-flex {
      min-height: auto !important;
      padding: 2px 7px !important;
      height: 100%;

      .mat-form-field-prefix {
        .mat-icon {
          margin-right: 2px !important;
        }
      }

      .mat-form-field-infix {
        .mat-input-element {
          margin-right: 7px !important;
          font-size: 12px !important;
        }
      }
    }
  }
}

.search-product-details {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      min-height: 40px !important;
    }
  }
}

.product-filter-toggle-button {
  .mat-icon {
    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
}

.font-12px {
  font-size: 12px;
}

.font-16px {
  font-size: 16px;
}

.cdk-overlay-pane.p-0 {
  .mat-dialog-container {
    padding: 0px !important;
  }
}

.fullscreen-dialog {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100%;
  height: 100%;

  .mat-dialog-container {
    border-radius: 0px !important;
  }
}

.timepicker {
  .timepicker__header {
    @apply bg-primary #{!important};
  }

  .clock-face__number > span.active {
    @apply bg-primary #{!important};
  }

  .timepicker-button {
    @apply text-primary-500 #{!important};
  }

  .clock-face__clock-hand,
  .clock-face__clock-hand::before,
  .clock-face__clock-hand::after {
    @apply bg-primary #{!important};
    @apply border-primary #{!important};
  }
}

.status-chip {
  width: fit-content;
  border-radius: 22px;
  display: block;
  position: relative;
  text-align: center;
  padding: 0;
  font-size: 12px;
}

.draft-status {
  background-color: #fff6cc;
  color: #ecbb00;
  padding: 0 8px;
  display: inline-block;
  text-align: center;
}

.completed-status {
  background-color: #dff6f5;
  color: #00a39b;
  padding: 0 8px;
}

.image-size {
  .cell-image {
    max-width: 30px !important;
    max-height: 30px !important;
    height: auto !important;
    width: auto !important;
  }
}

.badge {
  @apply rounded-full;
  padding: 1px 0.625rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  height: 20px;
  line-height: 19px;
  display: inline-block !important;
  white-space: nowrap;
}

$success: #0d9488;
$neutral: #4f46e5;
$warning: #dc8217;
$error: #e11d48;
$disabled: #94a3b8;

.color-success {
  color: $success !important;
}

.badge-success {
  color: #166534 !important;
  background: #bbf7d0 !important;
}

.badge-cancelled {
  color: #616161;
  background: #0000000f;
}

.color-neutral {
  color: $neutral !important;
}

.color-disabled {
  color: $disabled !important;
}

.badge-neutral {
  color: $neutral !important;
  background: #e0e7ff;
}

.badge-primary {
  color: var(--fuse-on-primary);
  background: var(--fuse-primary);
}

.badge-secondary-border {
  color: #64748b;
  background: #ffffff;
  border-color: #cbd5e1;
}

.color-warning {
  color: $warning !important;
}

.badge-warning {
  color: $warning !important;
  background: #fef3c7;
}

.badge-new {
  color: $warning !important;
  background: #fef3c7;
}

.color-error {
  color: $error !important;
}

.badge-error {
  color: $error !important;
  background: #ffe4e6;
}

.badge-exporting {
  color: $neutral !important;
  background: #e0e7ff;
}

.no-error-spacing {
  .mat-form-field-wrapper {
    margin-bottom: 0px !important;
  }
}

[class*='mtx-popover-below'] .mtx-popover-direction-arrow:before,
[class*='mtx-popover-below'] .mtx-popover-direction-arrow:after,
[class*='mtx-popover-above'] .mtx-popover-direction-arrow:before,
[class*='mtx-popover-above'] .mtx-popover-direction-arrow:after {
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}

[class*='mtx-popover'] .mtx-popover-direction-arrow:after {
  border-color: white;
}

[class*='mtx-popover'] .mtx-popover-direction-arrow:before {
  border-color: #0000001f;
}

.prevent-click {
  pointer-events: none;
}

.authorize-click {
  pointer-events: auto;
}

.column-toggler-menu {
  .mat-menu-content {
    padding: 0px !important;
  }
}

.height-40 {
  .mat-form-field-flex {
    min-height: unset !important;
    height: 40px !important;
  }
}

.full-width-checkbox.mat-checkbox {
  .mat-checkbox-layout {
    width: 100%;
  }
}

input[type='number'].hide-number-spinner::-webkit-inner-spin-button,
input[type='number'].hide-number-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-header-center-text {
  .mat-sort-header-container {
    justify-content: center !important;
  }
}

.sku-header {
  .mat-sort-header-container {
    justify-content: left !important;
  }
}

.mat-column-overlay {
  background: rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.08);
    width: 6px;
  }
}
.status-active {
  color: $success;
}

hr {
  margin: 0 -0.8rem;
  border-bottom-width: 0;
}

.br-50 {
  border-radius: 50% !important;
}

.white-space-normal {
  white-space: normal;
}

.mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
  &:hover {
    box-shadow: none;
    background-color: transparent;
  }
}

.w-60px {
  width: 60px;
}

.w-200px {
  width: 200px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fs-11 {
  font-size: 11px !important;
}

.w-40px {
  width: 40px;
}

.w-100 {
  width: 100% !important;
}
.m-auto {
  margin: auto !important;
}

.bg-primary {
  background-color: var(--eco-primary-color) !important;
}

.filter-tabs {
  gap: 8px;
  .tab {
    border-radius: 8px;
    padding: 4px 12px;
    font-weight: 500;
    font-size: 13px;

    cursor: pointer;
    &:hover {
      background: #f1f1f1;
    }
    &.is-selected {
      background: #e8e8e8;
    }
  }
}

.settings-table-container {
  height: calc(100vh - 205px);
  overflow: auto;
  background-color: white;
  .mat-table {
    tr.mat-header-row {
      height: 40px;
    }
  }
  .mat-header-cell,
  .mat-cell {
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.eco-admin-grid {
  .eco-admin-grid-header {
    // min-width: max-content;
    padding: 12px 16px;
    gap: 16px;
    grid-template-columns: repeat(5, 1fr);
    & > div:first-child {
      margin-left: 16px;
    }
  }
  .eco-admin-grid-body {
    // min-width: max-content;
    padding: 5px 16px;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    & > div:first-child {
      margin-left: 16px;
    }
    &:hover {
      background-color: #f2f4f6;
    }
  }
}

.compact-tr {
  .mat-table-sticky-border-elem-right,
  .mat-table-sticky-border-elem-left {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 3px;
      border-left: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      background: #f1f5f9;
    }
  }

  .mat-table-sticky-border-elem-right {
    &::after {
      content: '';
      left: 0;
      right: unset;
    }
  }
}

.mt-60px {
  margin-top: 60px;
}
