// Margin
.m-8px {
  margin: 8px !important;
}

.m-16px {
  margin: 16px !important;
}

.m-24px {
  margin: 24px !important;
}

// Margin Bottom
.mb-8px {
  margin-bottom: 8px !important;
}

.mb-16px {
  margin-bottom: 16px !important;
}

.mb-24px {
  margin-bottom: 24px !important;
}

.mb-32px {
  margin-bottom: 32px !important;
}

// Margin Top
.mt-8px {
  margin-top: 8px !important;
}

.mt-16px {
  margin-top: 16px !important;
}

.mt-24px {
  margin-top: 24px !important;
}

.mt-32px {
  margin-top: 32px !important;
}

// Margin Right
.mr-8px {
  margin-right: 8px !important;
}

.mr-16px {
  margin-right: 16px !important;
}

.mr-24px {
  margin-right: 24px !important;
}

.mr-32px {
  margin-right: 32px !important;
}

// Margin Left
.ml-8px {
  margin-left: 8px !important;
}

.ml-16px {
  margin-left: 16px !important;
}

.ml-24px {
  margin-left: 24px !important;
}

.ml-32px {
  margin-left: 32px !important;
}
